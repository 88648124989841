/**
 * This is a helper to make any promiseFn work with Reacts Suspense
 * Originally from: https://blog.logrocket.com/react-suspense-data-fetching/#building-sample-app-react-suspense
 * We've adjusted it for our needs. Comments are ours
 * @param promiseFn
 * @param preFetch
 */
function wrapPromiseFunction(
  promiseFn: (...args: any[]) => Promise<any>,
  preFetch: boolean = false,
): {read: () => any; prepare: (...args: any[]) => any} {
  let status = 'pending';
  let response;

  let promise;
  let args;

  //if prefetching, start the data loading now
  if (preFetch) {
    promise = promiseFn();
  }

  const prepare = (...params) => {
    args = params;
    promise = null;
  };

  const read = () => {
    //if we didn't prefetch we start data loading the first time someone tries to 'read' the data
    if (!promise) {
      // check if we prepared before reading
      status = 'pending';
      response = null;

      promise = promiseFn.apply(null, args);
    }
    const suspender = promise.then(
      (res) => {
        status = 'success';
        response = res;
      },
      (err) => {
        status = 'error';
        response = err;
      },
    );
    switch (status) {
      case 'pending':
        //throw a promise. Suspense will catch this thrown thing (like an error) and recognise that the component is still loading its data because the thing that is thrown is a promise.
        throw suspender;
      case 'error':
        //throw a real error. This will bubble up to the nearest ErrorBoundary
        throw response;
      default:
        //return an actual value
        return response;
    }
  };

  return {read, prepare};
}

export default wrapPromiseFunction;
