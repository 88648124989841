import React from 'react';
import './Spinner.scss';

export default function Spinner({active = true, margin = false}: {active?: boolean; margin?: boolean}) {
  return (
    <div
      className={['spinner', active && 'spinner--active', margin && 'margin'].join(' ')}
      role="progressbar"
      aria-busy={active ? 'true' : 'false'}
    />
  );
}
