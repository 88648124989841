import {lincd} from 'lincd-modules/lib/ontologies/lincd';
import {ShapeClass} from 'lincd-modules/lib/shapes/ShapeClass';
import {rdf} from 'lincd-rdf/lib/ontologies/rdf';
import {shacl} from 'lincd-shacl/lib/ontologies/shacl';
import {NamedNode} from 'lincd/lib/models';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {matchPath} from 'react-router-dom';
import {loadNodes} from '../hooks/registry';

export function getComponentPath(packageName: string, componentName: string) {
  return `/browse/packages/${packageName}/components/${componentName}`;
}

export function getOntologyPath(packageName: string, prefix: string, entityLabel?: string) {
  return `/browse/packages/${packageName}/ontology/${prefix}${entityLabel ? '#' + entityLabel : ''}`;
}

export function getPackagePath(packageName: string) {
  return `/browse/packages/${packageName}`;
}

export function getSearchPath(query: string) {
  return `/browse/search?q=${query}`;
}

export function getShapePath(packageName: string, shapeName: string): string {
  return `/browse/packages/${packageName}/shapes/${shapeName}`;
}

export async function getShapePathByShapeClass(shape: ShapeClass | NamedNode): Promise<string> {
  let shapeClass: ShapeClass;

  if (shape instanceof NamedNode) {
    if (shape.has(rdf.type, lincd.ShapeClass)) {
      shapeClass = new ShapeClass(shape);
    } else if (shape.has(rdf.type, shacl.NodeShape)) {
      let nodeShape = new NodeShape(shape);

      if (!nodeShape.hasInverseProperty(lincd.definesShape)) {
        await loadNodes(nodeShape);
      }

      shapeClass = nodeShape.hasInverseProperty(lincd.definesShape)
        ? new ShapeClass(nodeShape.getOneInverse(lincd.definesShape))
        : null;
    } else {
      throw new Error('Please supply a NamedNode, NodeShape or ShapeClass');
    }
  } else if (shape instanceof ShapeClass) {
    shapeClass = shape;
  }
  if (shapeClass) {
    let module = shapeClass.module;

    if (!module) {
      await loadNodes(shapeClass);
      module = shapeClass.module;
    }
    if (!(module.packageName || shapeClass.label)) {
      await loadNodes(module);
    }

    let packageName: string = module.packageName;
    let shapeName: string = shapeClass.label;

    return getShapePath(packageName, shapeName);
  } else {
    console.warn('Could not find shape class from ', shape);
  }
}

export const ROUTES = {
  component: 'packages/:npmPath/components/:componentName',
  ontology: 'packages/:npmPath/ontology/:ontologyPrefix',
  packages: 'packages/:npmPath',
  shapes: 'packages/:npmPath/shapes/:shapeName',
};

var sidenavPathPatterns: Map<string, string[]> = new Map<string, string[]>();

sidenavPathPatterns.set('browse', ['/browse/*']);
sidenavPathPatterns.set('examples', ['/examples/*']);

export function getActiveSidenavItem(curPath: string): string {
  let entries = sidenavPathPatterns.entries();

  for (let i = 0; i < sidenavPathPatterns.size; i++) {
    const entry = entries.next().value;
    const sidenavItem = entry[0];
    const patterns = entry[1];

    for (let j = 0; j < patterns.length; j++) {
      const pattern = patterns[j];

      if (matchPath(pattern, curPath)) {
        return sidenavItem;
      }
    }
  }

  return '';
}

var categoryPathPatterns: Map<string, string[]> = new Map<string, string[]>();

categoryPathPatterns.set('all', ['/browse/all', '/browse/search']);

categoryPathPatterns.set('components', ['/browse/components', '/browse/packages/:npmPath/components/:componentName']);

categoryPathPatterns.set('ontologies', ['/browse/ontologies', '/browse/packages/:npmPath/ontology/:ontologyPrefix']);

categoryPathPatterns.set('packages', ['/browse/packages', '/browse/packages/:npmPath']);

categoryPathPatterns.set('shapes', ['/browse/shapes', '/browse/packages/:npmPath/shapes/:shapeName']);

export const categories = Array.from(categoryPathPatterns.keys());

export type Category = typeof categories[number];

// Matches the current tab's path against a set of path
// patterns for the browse menu
export function getActiveCategory(curPath: string): Category {
  let entries = categoryPathPatterns.entries();

  for (let i = 0; i < categoryPathPatterns.size; i++) {
    const entry = entries.next().value;
    const category = entry[0];
    const patterns = entry[1];

    for (let j = 0; j < patterns.length; j++) {
      const pattern = patterns[j];

      if (matchPath(pattern, curPath)) {
        return category;
      }
    }
  }
}
