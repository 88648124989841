import {Suspense, useEffect, useState} from 'react';
import {Link, Route, Routes} from 'react-router-dom';
import {SearchBar} from './SearchBar';
import Spinner from './Spinner';
import {categories, Category, getActiveCategory, getActiveSidenavItem} from '../utils/navigate';

declare var process;

export default function Sidebar({setSidebarIsOpen, sidebarIsOpen, sidebarBreakpoint}) {
  const [activePage, setActivePage] = useState<string>();
  const [isBrowseDropdownOpen, setDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname) {
      let curPath = window.location.pathname;
      setActivePage(getActiveSidenavItem(curPath));

      // Automatically open the browse dropdown if the
      // initial page load is in the browse directory
      if (getActiveSidenavItem(curPath) == 'browse') {
        setDropdown(true);
      } else if (window.location.pathname == '/') {
        // Close the dropdown if navigated to from somewhere
        // other than the homepage
        setDropdown(false);
      }
    }
  }, [typeof window !== 'undefined' ? window.location.pathname : null]);
  const isActivePage = (page: string) => {
    return page == activePage;
  };

  const toggleBrowseDropdown = () => {
    setDropdown(!isBrowseDropdownOpen);
  };

  const toggleNav = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const closeNavIfMobile = () => {
    if (window.innerWidth < sidebarBreakpoint) toggleNav();
  };

  return (
    <>
      <div className="min-w-screen-md">
        <button className="toggle-main-nav" onClick={toggleNav}>
          {' '}
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M11 13H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1 7H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </button>
      </div>

      <div className="sidenav-top">
        <div className="logo">
          <Link to="/" onClick={closeNavIfMobile}>
            <svg width="188" height="75" viewBox="0 0 188 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M42.8816 42.5432C40.0885 39.7501 40.0885 35.2216 42.8816 32.4285C44.0122 31.2978 45.4212 30.627 46.8877 30.4107L59.3072 30.3669C60.9024 30.5206 62.4509 31.2063 63.673 32.4285C66.4662 35.2216 66.4662 39.7501 63.673 42.5432C62.4493 43.767 60.8981 44.453 59.3007 44.6054L51.4161 44.6243C51.2064 44.8745 50.9839 45.1176 50.7486 45.3529C50.0969 46.0046 49.384 46.5587 48.6285 47.015L59.4091 46.9893C61.5789 46.8092 63.6988 45.8891 65.3588 44.229C69.083 40.5049 69.083 34.4668 65.3588 30.7427C63.6988 29.0826 61.5789 28.1625 59.4092 27.9824L46.7209 28.0272C44.7007 28.2859 42.7474 29.1911 41.1958 30.7427C37.8509 34.0876 37.5103 39.2991 40.1739 43.0242L43.4029 43.0164C43.2235 42.8688 43.0494 42.7111 42.8816 42.5432Z"
                fill="#02ABF2"
              />
              <path
                d="M27.1469 42.5432C24.3538 39.7501 24.3538 35.2216 27.1469 32.4284C28.2775 31.2978 29.6865 30.627 31.153 30.4107L39.3752 30.3817C39.5933 30.1194 39.8253 29.8648 40.0713 29.6188C40.7082 28.9819 41.4036 28.4382 42.1402 27.9878L30.9862 28.0272C28.966 28.2859 27.0127 29.191 25.4611 30.7427C21.7369 34.4668 21.7369 40.5048 25.4611 44.229C27.2463 46.0142 29.5632 46.9436 31.902 47.0173L43.6744 46.9892C45.8442 46.8092 47.964 45.8891 49.6241 44.229C52.9573 40.8958 53.3072 35.7091 50.6739 31.9867L47.4685 31.998C47.6298 32.1332 47.7866 32.2767 47.9383 32.4284C50.7314 35.2216 50.7314 39.7501 47.9383 42.5432C46.7145 43.767 45.1634 44.453 43.566 44.6054L31.9396 44.6332C30.1955 44.5691 28.4771 43.8734 27.1469 42.5432Z"
                fill="white"
              />
              <path
                d="M94.6652 45.9609H85.6551C85.34 45.9609 85.1824 45.8034 85.1824 45.4882V28.5527C85.1824 28.2375 85.34 28.0799 85.6551 28.0799H87.4071C87.7222 28.0799 87.8798 28.2375 87.8798 28.5527V43.2635C87.8798 43.4489 87.9911 43.5416 88.2135 43.5416H94.6652C94.9803 43.5416 95.1379 43.7084 95.1379 44.0421V45.4882C95.1379 45.8034 94.9803 45.9609 94.6652 45.9609ZM104.431 28.5527V45.4882C104.431 45.8034 104.274 45.9609 103.959 45.9609H102.207C101.892 45.9609 101.734 45.8034 101.734 45.4882V28.5527C101.734 28.2375 101.892 28.0799 102.207 28.0799H103.959C104.274 28.0799 104.431 28.2375 104.431 28.5527ZM114.719 45.9609H112.995C112.679 45.9609 112.522 45.8034 112.522 45.4882V28.5527C112.522 28.2375 112.679 28.0799 112.995 28.0799H114.552C114.848 28.0799 115.052 28.1819 115.164 28.3858L121.893 40.4826H122.005V28.5527C122.005 28.2375 122.162 28.0799 122.477 28.0799H124.201C124.517 28.0799 124.674 28.2375 124.674 28.5527V45.4882C124.674 45.8034 124.517 45.9609 124.201 45.9609H122.7C122.403 45.9609 122.162 45.8126 121.977 45.516L115.303 33.5582H115.191V45.4882C115.191 45.8034 115.034 45.9609 114.719 45.9609ZM139.886 45.9609H136.66C135.288 45.9609 134.222 45.5809 133.462 44.8208C132.72 44.0607 132.35 43.0039 132.35 41.6506V32.3903C132.35 31.0184 132.72 29.9617 133.462 29.2201C134.222 28.46 135.288 28.0799 136.66 28.0799H139.886C141.239 28.0799 142.296 28.46 143.056 29.2201C143.816 29.9617 144.196 31.0184 144.196 32.3903V33.7251C144.196 34.0588 144.029 34.2257 143.696 34.2257H141.944C141.629 34.2257 141.471 34.0588 141.471 33.7251V32.5015C141.471 31.1296 140.804 30.4437 139.469 30.4437H137.049C135.715 30.4437 135.047 31.1296 135.047 32.5015V41.5394C135.047 42.9112 135.715 43.5972 137.049 43.5972H139.469C140.804 43.5972 141.471 42.9112 141.471 41.5394V40.3158C141.471 39.9821 141.629 39.8152 141.944 39.8152H143.696C144.029 39.8152 144.196 39.9821 144.196 40.3158V41.6506C144.196 43.0039 143.816 44.0607 143.056 44.8208C142.296 45.5809 141.239 45.9609 139.886 45.9609Z"
                fill="white"
              />
              <path
                d="M154.542 43.5972H158.463C159.798 43.5972 160.465 42.9112 160.465 41.5394V32.5015C160.465 31.1296 159.798 30.4437 158.463 30.4437H154.542C154.338 30.4437 154.236 30.5364 154.236 30.7218V43.2913C154.236 43.4952 154.338 43.5972 154.542 43.5972ZM151.539 45.4882V28.5527C151.539 28.2375 151.696 28.0799 152.012 28.0799H158.852C160.206 28.0799 161.263 28.46 162.023 29.2201C162.801 29.9617 163.191 31.0184 163.191 32.3903V41.6506C163.191 43.0039 162.801 44.0607 162.023 44.8208C161.263 45.5809 160.206 45.9609 158.852 45.9609H152.012C151.696 45.9609 151.539 45.8034 151.539 45.4882Z"
                fill="#02ABF2"
              />
            </svg>
          </Link>
          <div>
            <svg
              onClick={toggleNav}
              width="22"
              height="14"
              viewBox="0 0 22 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M11 13H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M1 7H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        <div className="sidenav-main-links">
          <nav>
            {/*Commented out for live site <a href="#">Create</a> */}
            <a
              id="browse-dropdown"
              className={`${isBrowseDropdownOpen ? 'dropdown-is-open' : ''} ${
                isActivePage('browse') ? 'active-tab' : ''
              }`}
              onClick={toggleBrowseDropdown}
            >
              Browse
              <img src="/images/Chevron-Arrow-Down.svg" />
            </a>
            {isBrowseDropdownOpen && (
              <Routes>
                <Route
                  path={'/*'}
                  element={<BrowseSubMenu closeNavIfMobile={closeNavIfMobile} setActivePage={setActivePage} />}
                ></Route>
              </Routes>
            )}
            <Link
              to="/examples"
              className={isActivePage('examples') ? 'active-tab' : ''}
              onClick={() => {
                setActivePage('examples');
                closeNavIfMobile();
              }}
            >
              Examples
            </Link>
            <a href={process.env.DOCS_URL} target="lincd-docs">
              Documentation
            </a>
          </nav>
        </div>
        <SearchBar
          closeNavIfMobile={() => {
            closeNavIfMobile();
          }}
        />
      </div>

      <div className="social-media flex">
        <a href="https://twitter.com/lincd_org">
          <svg width="19" height="20" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1892_3395)">
              <path
                d="M16.8245 3.7706C16.8355 3.93015 16.8355 4.08971 16.8355 4.25074C16.8355 9.15736 13.1002 14.8162 6.27004 14.8162V14.8132C4.25239 14.8162 2.27666 14.2382 0.578125 13.1485C0.871508 13.1838 1.16636 13.2015 1.46195 13.2022C3.13401 13.2037 4.75827 12.6427 6.07372 11.6096C4.48474 11.5794 3.09136 10.5434 2.6046 9.03089C3.16121 9.13824 3.73474 9.11618 4.28107 8.96692C2.54871 8.61692 1.30239 7.09486 1.30239 5.32721C1.30239 5.31104 1.30239 5.2956 1.30239 5.28015C1.81857 5.56765 2.39651 5.72721 2.98769 5.74486C1.35607 4.65442 0.853125 2.48383 1.83842 0.786774C3.72371 3.10663 6.50533 4.51692 9.49137 4.66618C9.1921 3.37648 9.60092 2.02501 10.5656 1.11839C12.0612 -0.287491 14.4134 -0.215432 15.8193 1.27942C16.6509 1.11545 17.448 0.810303 18.1774 0.37795C17.9002 1.23751 17.32 1.96766 16.545 2.43163C17.2811 2.34486 18.0002 2.1478 18.6774 1.84707C18.1789 2.59413 17.5509 3.24486 16.8245 3.7706Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1892_3395">
                <rect width="18.2353" height="15" fill="white" transform="translate(0.503906)" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <a href="https://github.com/Semantu/lincd">
          <svg width="20" height="20" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.42816 0C4.18172 0 0.738281 3.44279 0.738281 7.68988C0.738281 11.0875 2.94167 13.97 5.99709 14.9868C6.38141 15.058 6.5225 14.82 6.5225 14.6169C6.5225 14.4335 6.51537 13.8277 6.51206 13.1852C4.37273 13.6503 3.9213 12.2779 3.9213 12.2779C3.57149 11.389 3.06748 11.1527 3.06748 11.1527C2.36978 10.6754 3.12007 10.6852 3.12007 10.6852C3.89227 10.7393 4.29887 11.4778 4.29887 11.4778C4.98473 12.6533 6.09782 12.3134 6.53664 12.1169C6.60565 11.6199 6.80494 11.2805 7.02486 11.0886C5.31683 10.8942 3.52132 10.2348 3.52132 7.28824C3.52132 6.44868 3.82172 5.7627 4.31364 5.22417C4.2338 5.03036 3.97058 4.24836 4.38814 3.18913C4.38814 3.18913 5.03388 2.98246 6.5034 3.97737C7.1168 3.80699 7.77465 3.72155 8.42816 3.71862C9.08168 3.72155 9.74003 3.80699 10.3546 3.97737C11.8223 2.98246 12.4672 3.18913 12.4672 3.18913C12.8857 4.24836 12.6224 5.03036 12.5426 5.22417C13.0356 5.7627 13.334 6.44868 13.334 7.28824C13.334 10.2418 11.535 10.8921 9.82268 11.0825C10.0985 11.3211 10.3443 11.7891 10.3443 12.5066C10.3443 13.5355 10.3354 14.3636 10.3354 14.6169C10.3354 14.8215 10.4738 15.0613 10.8636 14.9858C13.9173 13.9678 16.1179 11.0863 16.1179 7.68988C16.1179 3.44279 12.675 0 8.42816 0Z"
              fill="white"
            />
            <path
              d="M3.65126 11.0405C3.63432 11.0788 3.57422 11.0901 3.51946 11.0639C3.46369 11.0388 3.43236 10.9867 3.45044 10.9484C3.46699 10.9092 3.52723 10.8982 3.58288 10.9243C3.63878 10.9496 3.67061 11.0021 3.65126 11.0405Z"
              fill="white"
            />
            <path
              d="M3.9639 11.3883C3.92722 11.4223 3.85553 11.4065 3.80689 11.3528C3.75659 11.2992 3.74716 11.2275 3.78435 11.193C3.82217 11.1591 3.89169 11.175 3.94212 11.2285C3.99242 11.2828 4.00222 11.354 3.9639 11.3883Z"
              fill="white"
            />
            <path
              d="M4.26559 11.8306C4.21847 11.8633 4.14143 11.8326 4.0938 11.7643C4.04669 11.6959 4.04669 11.6139 4.09482 11.581C4.14258 11.5481 4.21847 11.5778 4.26673 11.6456C4.31372 11.7151 4.31372 11.7971 4.26559 11.8306Z"
              fill="white"
            />
            <path
              d="M4.68181 12.2591C4.63966 12.3056 4.54988 12.2931 4.48417 12.2297C4.41694 12.1677 4.39822 12.0797 4.4405 12.0332C4.48315 11.9866 4.57344 11.9997 4.63966 12.0626C4.70638 12.1245 4.72676 12.2131 4.68181 12.2591Z"
              fill="white"
            />
            <path
              d="M5.25526 12.5085C5.23667 12.5687 5.1502 12.5961 5.0631 12.5705C4.97613 12.5441 4.91921 12.4736 4.93678 12.4127C4.95486 12.3521 5.04171 12.3236 5.12945 12.351C5.21629 12.3772 5.27334 12.4472 5.25526 12.5085Z"
              fill="white"
            />
            <path
              d="M5.88513 12.5527C5.8873 12.6161 5.81344 12.6687 5.72201 12.6699C5.63007 12.6719 5.55571 12.6206 5.55469 12.5582C5.55469 12.4941 5.62689 12.442 5.71883 12.4405C5.81026 12.4387 5.88513 12.4897 5.88513 12.5527Z"
              fill="white"
            />
            <path
              d="M6.46849 12.4535C6.47944 12.5154 6.4159 12.5789 6.3251 12.5958C6.23584 12.6121 6.15319 12.5739 6.14186 12.5126C6.13078 12.4491 6.19547 12.3856 6.28461 12.3692C6.37553 12.3534 6.4569 12.3906 6.46849 12.4535Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="https://discord.gg/2PFnf7udET">
          <svg width="20" height="20" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1892_3407)">
              <g clipPath="url(#clip1_1892_3407)">
                <path
                  d="M16.5317 1.25625C15.2774 0.669375 13.9362 0.242861 12.534 0C12.3618 0.311339 12.1606 0.730097 12.0219 1.06322C10.5313 0.839056 9.05449 0.839056 7.59134 1.06322C7.45266 0.730097 7.24691 0.311339 7.07316 0C5.66947 0.242861 4.32673 0.670942 3.0724 1.25935C0.542395 5.08252 -0.143448 8.81073 0.199474 12.486C1.87751 13.7391 3.50372 14.5003 5.10249 14.9985C5.49724 14.4552 5.84929 13.8777 6.15259 13.269C5.57496 13.0495 5.02171 12.7787 4.49896 12.4642C4.63765 12.3615 4.7733 12.254 4.90436 12.1435C8.09276 13.6348 11.557 13.6348 14.7074 12.1435C14.84 12.254 14.9756 12.3615 15.1128 12.4642C14.5885 12.7802 14.0337 13.0511 13.4561 13.2706C13.7594 13.8777 14.1099 14.4567 14.5062 15C16.1065 14.5019 17.7342 13.7407 19.4122 12.486C19.8146 8.22542 18.7249 4.53146 16.5317 1.25625ZM6.58696 10.2257C5.62984 10.2257 4.84491 9.3322 4.84491 8.2441C4.84491 7.15599 5.61307 6.26091 6.58696 6.26091C7.56088 6.26091 8.34577 7.15442 8.32901 8.2441C8.33052 9.3322 7.56088 10.2257 6.58696 10.2257ZM13.0248 10.2257C12.0676 10.2257 11.2827 9.3322 11.2827 8.2441C11.2827 7.15599 12.0508 6.26091 13.0248 6.26091C13.9986 6.26091 14.7836 7.15442 14.7668 8.2441C14.7668 9.3322 13.9986 10.2257 13.0248 10.2257Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1892_3407">
                <rect width="19.375" height="15" fill="white" transform="translate(0.117188)" />
              </clipPath>
              <clipPath id="clip1_1892_3407">
                <rect width="77.5" height="15" fill="white" transform="translate(0.117188)" />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
    </>
  );
}

// Used for checking the sidenav's browse subtab menu
// and sets the tab as being active if necessary
function isActive(category: Category, curPath: string): boolean {
  return category == getActiveCategory(curPath);
}

// Displays the browse submenu's categories
function BrowseSubMenu({
  closeNavIfMobile,
  setActivePage,
}: {
  closeNavIfMobile: () => void;
  setActivePage: (page: string) => void;
}) {
  return (
    <>
      <nav className="nav-upper-links">
        <Suspense fallback={<Spinner />}>
          {categories.map((category) => {
            return (
              <Link
                key={category}
                to={`/browse/${category}`}
                className={isActive(category, window.location.pathname) ? 'active-tab' : ''}
                onClick={() => {
                  closeNavIfMobile();
                  setActivePage('browse');
                }}
              >
                {category}
              </Link>
            );
          })}
        </Suspense>
      </nav>
    </>
  );
}
