import React from 'react';
import '../scss/globals.scss';
import {Alert} from '../components/Alert';

export const ErrorPage = () => {
  return <div className='PageContent'>
    <Alert type={'warning'}>
      Oops, something went wrong
    </Alert>
  </div>;
;
}
