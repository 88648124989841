import wrapPromiseFunction from '../utils/wrapPromise';
import {NamedNode} from 'lincd/lib/models';
import {JSONParser} from 'lincd-jsonld/lib/utils/JSONParser';
import {JSONWriter} from 'lincd-jsonld/lib/utils/JSONWriter';
import {NodeSet} from 'lincd/lib/collections/NodeSet';
import {JSONLD} from 'lincd-jsonld/lib/utils/JSONLD';
import {Component} from 'lincd-modules/lib/shapes/Component';
import {Module} from 'lincd-npm/lib/shapes/Module';
import {useEffect, useState} from 'react';
import {Shape} from 'lincd/lib/shapes/Shape';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {OntologyFile} from 'lincd-modules/lib/shapes/OntologyFile';
declare var process: any;
const SERVER_REQUEST_PATH =
  (typeof window !== 'undefined'
    ? window.location.protocol + '//' + window.location.host
    : `http://localhost:${process.env.PORT || 4000}`) + '/request';
const cache: Map<string, any> = new Map<string, any>();
export function useRegistry(type: NamedNode | NamedNode[], searchLabel?: string, preFetch: boolean = false) {
  return wrapPromiseFunction((limit, offset) => fetchRegistry(type, searchLabel, limit, offset), preFetch);
}

export function fetchRegistry(
  type: NamedNode | NamedNode[],
  searchLabel?: string,
  limit?: number,
  offset: number = 0,
): Promise<{
  data: NodeSet;
  instances: NodeSet;
  registryURLs: [];
}> {
  let url = SERVER_REQUEST_PATH + '/any';
  let types: NodeSet<NamedNode>;
  if (type instanceof NodeSet) {
    types = type as NodeSet<NamedNode>;
  } else {
    types = new NodeSet(Array.isArray(type) ? type : [type]);
  }
  return JSONWriter.stringify({types, searchLabel, limit, offset})
    .then((body) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify({types: types.map(t =>t.uri).join(""), searchLabel}),
        body,
      })
        .then((res) => {
          if (!res.ok) {
            // console.warn(url+ " returned "+res.status+' - '+res.statusText);
            throw new Error(url + ' returned ' + res.status + ' - ' + res.statusText);
          }
          return res.json();
        })
        .then((res) => {
          return JSONParser.parseObject(res);
        });
    })
    .catch((err) => {
      console.warn('Error during registry request: ', err);
    });
}

export function useComponent(npmPath, componentName) {
  let url = SERVER_REQUEST_PATH + '/component';
  let params = {
    npmPath,
    componentName,
  };

  return callRegistryAPI(url, params).then((res) => {
    return {component: res.component, nodeShape: res.nodeShape, shapeClass: res.shapeClass};
  });
}

export function useHomepageStatistics(): Promise<{componentQty: number; shapeQty: number; ontologyQty: number}> {
  let url = SERVER_REQUEST_PATH + '/homepage-stats';
  let params = {};

  return callRegistryAPI(url, params).then((res) => {
    return {componentQty: res.componentQty, shapeQty: res.shapeQty, ontologyQty: res.ontologyQty};
  });
}

export function useModule({
  npmPath,
  ontologyURI,
  prefix,
}: {
  npmPath?: string;
  ontologyURI?: string;
  prefix?: string;
}): Promise<{source: NamedNode; shapes: any}> {
  let url = SERVER_REQUEST_PATH + '/module';
  let params = {
    npmPath,
    ontologyURI,
    prefix,
  };

  return callRegistryAPI(url, params, true).then((response) => {
    return {source: response?.module, shapes: response?.shapes};
  });
}

export function loadNodes(...nodesShapesOrUris: (string | NamedNode | Shape)[]): Promise<NodeSet> {
  let URIs = nodesShapesOrUris.map((entry) => {
    if (entry instanceof Shape) return entry.node.value;
    else if (entry instanceof NamedNode) return entry.uri;
    else if (typeof entry === 'string') return entry;
  }) as string[];

  let url = SERVER_REQUEST_PATH + '/load-nodes';
  let params = {
    URIs,
  };

  return callRegistryAPI(url, params).then((res) => {
    return res.nodes;
  });
}

//TODO: not a proper hook, rename to fetchOntologyFromParams ?
export function useOntology(npmPath: string, ontologyPrefix: string) {
  let params = {
    npmPath,
    ontologyPrefix,
  };
  return fetchOntologyData(params);
}
export function fetchOntology(ontology: NamedNode) {
  return fetchOntologyData({ontology});
}

export function fetchOntologyData(params) {
  let url = SERVER_REQUEST_PATH + '/ontology';
  return callRegistryAPI(url, params).then((res) => {
    if (res.jsonld) {
      let promise = JSONLD.parseString(res.jsonld);
      return {
        components: res.components,
        jsonld: res.jsonld,
        jsonldParsePromise: promise,
        ontologyFile: res.ontologyFile,
        relatedShapes: res.relatedShapes,
        ontology: res.ontology,
        moduleNode: res.module,
      };
    }
    //not found
    return null;
  });
}

export function useOntologyEntities(ontology: NamedNode, preFetch: boolean = false) {
  let url = SERVER_REQUEST_PATH + '/ontology-entities';
  let params = {
    ontology,
  };

  // return wrapPromiseFunction(() => callRegistryAPI(url, params), preFetch);
  return callRegistryAPI(url, params);
}

export function usePropertyShape(propertyShapeNode: NamedNode) {
  let url = SERVER_REQUEST_PATH + '/propertyshape';
  let params = {
    shapeURI: propertyShapeNode.uri,
  };

  return callRegistryAPI(url, params).then((res) => {
    return res;
  });
}

export function fetchShape(npmPath, shapeName) {
  let url = SERVER_REQUEST_PATH + '/shape';
  let params = {
    npmPath,
    shapeName,
  };

  return callRegistryAPI(url, params);
}

function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}
function callRegistryAPI(url: string, params, cacheResults: boolean = true) {
  if (cacheResults && cache.has(url)) {
    //get all the params of previous times this url has been called
    let paramsArr = cache.get(url);

    //if some previous params
    for (var [previousParams, previousPromise] of paramsArr) {
      //deeply matches the current params
      if (deepEqual(previousParams, params)) {
        //then reuse previous results
        return previousPromise;
      }
    }
  }

  let promise = JSONWriter.stringify(params)
    .then((body) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify({types: types.map(t =>t.uri).join(""), searchLabel}),
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          // console.log(res);
          return JSONParser.parseObject(res);
        });
    })
    .catch((err) => {
      console.warn('Error during registry request: ', err);
      throw err;
    });

  if (cacheResults) {
    if (!cache.has(url)) {
      cache.set(url, []);
    }
    //save the promise in cache
    cache.get(url).push([params, promise]);
  }
  return promise;
}
