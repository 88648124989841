export default function Html({assets, children, title}) {
  return (
    <html lang="en" suppressHydrationWarning={true}>
      {globalThis.document?.head ? (
        <head dangerouslySetInnerHTML={{__html: document.head.innerHTML}} />
      ) : (
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="shortcut icon" href="/static/favicon.ico" />
          <link rel="stylesheet" href={assets['main.css']} />
          {assets['tailwind-cdn'] && <script src={assets['tailwind-cdn']}></script>}
          <title>{title}</title>
        </head>
      )}
      <body>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<b>Enable JavaScript to run this app.</b>`,
          }}
        />
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `assetManifest = ${JSON.stringify(assets)};`,
          }}
        />
      </body>
    </html>
  );
}
