//Note: you need the whole class name for tailwind to recognise it and include it.. you can not dynamically generate classnames!
// let base = `p-4 my-4 border `;
// let styles = {
// 	info: base + 'bg-sky-700 text-sky-50 border-sky-700',
// 	warning: base + 'bg-amber-700 text-amber-50 border-amber-700',
// 	success: base + 'bg-emerald-700 text-emerald-50 border-emerald-700',
// 	error: base + 'bg-red-700 text-red-50 border-red-700',
// };
import './Alert.scss';

export function Alert({type, children}: {children?; type?: 'info' | 'warning' | 'success' | 'error'}) {
  type = type || 'info';
  // let className = styles[type];
  //className
  return <div className={'alert ' + type}>{children}</div>;
}
